import { Directive, ElementRef, Input, Renderer2, AfterViewInit, RendererStyleFlags2, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { CheckPermissions, CurrentNavigationService, PermissionService, SessionStore } from '@parashift/shared/services';
import { select } from '@parashift/shared/utils';

@Directive({
  selector: '[checkPermissions]',
  standalone: true
})
export class CheckPermissionsDirective implements AfterViewInit {

  @Input() checkPermissions: CheckPermissions;
  @Input() checkPermissionsDisplay: string = 'block';

  readonly parashiftPowers$: Observable<boolean>;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private permissionService: PermissionService,
    private currentNavigationService: CurrentNavigationService,
    private sessionStore: SessionStore,
    @Inject(DestroyRef) private destroyRef: DestroyRef
  ) {
    this.parashiftPowers$ = this.sessionStore.state$.pipe(select(state => state.parashift_powers));
  }

  ngAfterViewInit() {
    this.isUserAllowed();
    this.listenForNavigationChanges();
    this.listenForParashiftPowersChanges();
  }

  isUserAllowed() {
    const { roleIsAllowed, validPermissions, validTenantOwnerPermissions } = this.permissionService.validatePermissions(this.checkPermissions);
    if ((!roleIsAllowed && !validPermissions  && !validTenantOwnerPermissions) || (roleIsAllowed && this.checkPermissions.checkParashiftPowers && !this.sessionStore.state.parashift_powers)) {
      this.renderer.setStyle(this.element.nativeElement, 'display', 'none', RendererStyleFlags2.Important);
    } else {
      (this.checkPermissions.explicitlyShow || this.checkPermissions.checkParashiftPowers) && this.renderer.setStyle(this.element.nativeElement, 'display', this.checkPermissionsDisplay, RendererStyleFlags2.Important);
    }
  }

  private listenForNavigationChanges() {
    this.currentNavigationService.navigationChanged$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(changed => {
        if (changed) {
          this.isUserAllowed();
        }
      });
  }

  private listenForParashiftPowersChanges() {
    this.parashiftPowers$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.isUserAllowed());
  }
}
